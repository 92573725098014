import {Component, OnDestroy, OnInit} from '@angular/core';
import {Painting} from '../painting';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {PaintingService} from '../painting.service';
import {UrlParamUtil} from '@utils/url-param.util';
import {fadeInOutAnimation} from '@animations/fade.animation';

@Component({
  selector: 'app-gallery-detail',
  templateUrl: './gallery-detail.component.html',
  styleUrls: ['./gallery-detail.component.scss'],
  animations: [fadeInOutAnimation]
})
export class GalleryDetailComponent implements OnInit, OnDestroy {
  painting: Painting | undefined;
  paintingObs: Observable<Painting | undefined>;
  private routeParamsSubscription: Subscription;
  private paintingSubscription: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private paintingService: PaintingService) {
    this.routeParamsSubscription = this.route.paramMap
      .subscribe(params => {
        const param: string | null = params.get('title');
        this.handleRouteChange(param);
      });

    this.paintingObs = this.paintingService.getPainting();
    this.paintingSubscription = this.paintingObs
      .subscribe(painting => {
        this.painting = painting;
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.routeParamsSubscription.unsubscribe();
    this.paintingSubscription.unsubscribe();
  }

  navigateToNextPainting(): void {
    const painting = this.paintingService.getNextPainting();
    const param = UrlParamUtil.ConvertToUrlParameter(painting.title);
    this.router.navigate(['galerij', param]);
  }

  navigateToPreviousPainting(): void {
    const painting = this.paintingService.getPreviousPainting();
    const param = UrlParamUtil.ConvertToUrlParameter(painting.title);
    this.router.navigate(['galerij', param]);
  }

  paintingSizeValid = () => this.painting?.size.width != 0 && this.painting?.size.height != 0;

  private handleRouteChange(param: string | null): void {
    this.paintingService.selectPainting(param);
  }
}
