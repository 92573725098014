<div *ngIf="paintingObs | async as painting"
     class="gallery-detail">
  <div class="uk-container">
    <div class="uk-grid">

      <div class="uk-width-1-1 uk-width-3-5@m">
        <div class="uk-grid">

          <div class="uk-width-1-1">
            <div class="gallery-buttons">
              <a routerLink="../"
                 class="back-to-gallery-button">
                <i class="fa-regular fa-arrow-up-left fa-lg"></i>
                <span>Terug naar galerij</span>
              </a>
              <a (click)="navigateToPreviousPainting()" class="">
                <i class="fa-regular fa-chevron-left fa-lg"></i>
              </a>
              <a (click)="navigateToNextPainting()" class="">
                <i class="fa-regular fa-chevron-right fa-lg"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-2-5 uk-visible@m"></div>


      <div class="uk-grid">

        <div class="painting-image-container uk-width-1-1 uk-width-3-5@m"
             [@fadeInOutAnimation]="painting">
          <img class="painting-image"
               [src]="painting.image_path"
               [title]="painting.title"
               [alt]="painting.title">
        </div>

        <div class="painting-table-container uk-width-1-1 uk-width-2-5@m">
          <table class="painting-table uk-table uk-table-divider"
                 uk-sticky="end:true; offset: 16px;">
            <tbody>
            <tr>
              <td colspan="2"><h2 class="uk-heading-small">Details</h2></td>
            </tr>
            <tr>
              <td class="uk-table-shrink">Titel</td>
              <td class="uk-width-expand">{{ painting.title }}</td>
            </tr>
            <tr>
              <!--              <td>Afmeting <br><span class="uk-text-nowrap">(breedte x hoogte)</span></td>-->
              <td class="uk-text-nowrap">Afmeting (bxh)</td>
              <td *ngIf="paintingSizeValid()">{{ painting.size!.width }} x {{ painting.size!.height }} cm</td>
              <td *ngIf="!paintingSizeValid()">Onbekend</td>
            </tr>
            <tr>
              <td>Techniek</td>
              <td>{{ painting.technique }}</td>
            </tr>
            <tr>
              <td>Prijs</td>
              <td>{{ painting | price }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</div>
