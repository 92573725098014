<div class="menu uk-hidden@m">
  <div class="uk-container">

    <div class="menu-item-container">

      <div class="menu-item">
        <h1 class="uk-heading uk-heading-medium">Navigatie</h1>
        <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a>
        <a routerLink="galerij" routerLinkActive="active">Galerij</a>
        <a routerLink="over-mij" routerLinkActive="active">Over mij</a>
        <a routerLink="contact" routerLinkActive="active">Contact</a>
      </div>

<!--      <div class="menu-item">-->
<!--        <h2 class="menu-heading">Socials</h2>-->
<!--        <div class="social-icons">-->
<!--          <app-social-icon [href]="'https://www.instagram.com/rebo013/'">-->
<!--            <i class="fa-brands fa-instagram fa-lg"></i></app-social-icon>-->
<!--          <app-social-icon [href]="'https://www.facebook.com/rebo013/'">-->
<!--            <i class="fa-brands fa-facebook-f fa-lg"></i></app-social-icon>-->
<!--        </div>-->
<!--      </div>-->

      <div class="menu-item">
        <hr>
        <div class="social-icons">
          <app-social-icon [href]="'tel:0623943922'">
            <i class="fa-regular fa-mobile fa-lg"></i>
            <span text>06 2394 3922</span>
          </app-social-icon>
          <app-social-icon [href]="'mailto:r.boons@icloud.com'">
            <i class="fa-regular fa-envelope fa-lg"></i>
            <span text>r.boons@icloud.com</span>
          </app-social-icon>
        </div>
      </div>

      <div class="menu-item">
        <hr>
        <div class="uk-grid">
          <div class="uk-width-1-1 uk-width-expand@m uk-text-center uk-text-left@m">{{ '&copy; 2020 - ' + getCurrentYear() }}</div>
          <div class="uk-width-1-1 uk-width-auto@m uk-text-center uk-text-left@m">Webapplicatie door Felix Boons</div>
        </div>
      </div>

    </div>

    <!--  <app-logo></app-logo>-->
  </div>
</div>
