import { Component, OnInit } from '@angular/core';
import {slideAnimation} from '@animations/slide.animation';
import {fadeInOutAnimation} from '@animations/fade.animation';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [fadeInOutAnimation, slideAnimation]
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
