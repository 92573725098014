import * as latinize from 'latinize';

export class UrlParamUtil {

  static ConvertToUrlParameter = (...params: string[]): string => {
    const param = params.join(' ');
    return latinize(param) // Convert to Latin
      .replace(/-/g, "") // Remove all existing dashes
      .replace(/\s+/g, '-') // Replace all whitespaces with dashes
      .toLowerCase();
  }
}
