import {Injectable} from '@angular/core';
import {Painting} from './painting';
import _paintings from './paintings.json';
import {UrlParamUtil} from '@utils/url-param.util';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaintingService {
  private readonly paintings: Painting[];
  private painting = new BehaviorSubject<Painting | undefined>(undefined);
  private imagesLoaded = false;
  private index: number = 0;


  constructor() {
    this.paintings = _paintings;
  }

  // Should be handled by a different service vv
  setImagesLoaded = (): void => {
    this.imagesLoaded = true;
  };
  getImagesLoaded = () => this.imagesLoaded;
  // Should be handled by a different service ^^

  getPainting = (): Observable<Painting | undefined> => this.painting;

  getAll = (): Painting[] => this.paintings;

  selectPainting(param: string | null): void {
    const index = this.paintings.findIndex(p => param == UrlParamUtil.ConvertToUrlParameter(p.title));
    const painting = this.paintings[index];
    if (!painting) throw Error('PaintingService: selectPainting returned undefined');
    this.index = index;
    this.painting.next(painting);
  }

  getNextPainting(): Painting {
    const index = this.index == this.paintings.length - 1 ? this.index = 0 : ++this.index;
    return this.paintings[index];
  }

  getPreviousPainting(): Painting {
    const index = this.index == 0 ? this.index = this.paintings.length - 1 : --this.index;
    return this.paintings[index];
  }

  getSelectedPainting = (): Painting | undefined => this.painting.getValue();

}
