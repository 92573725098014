import {AfterContentInit, AfterViewInit, Component, Input, OnInit} from '@angular/core';

export interface Image {
  source_path: string;
  title: string;
  width: number;
  height: number;
}

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, AfterContentInit, AfterViewInit{
  @Input() source_path: string | undefined;
  @Input() title: string | undefined;
  @Input() width: number | undefined;
  @Input() height: number | undefined;
  @Input() image: Image | undefined;
  loaded = false;


  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
  }

  ngAfterViewInit(): void {
  }


}
