
<!--<div class="uk-container-fluid">-->
  <app-nav [menuOpen]="menuOpen"
           (menuToggled)="setMenuOpen($event)"></app-nav>

  <app-menu *ngIf="menuOpen"></app-menu>

  <main>
    <router-outlet (activate)="onActivate()"></router-outlet>
  </main>

  <hr>
  <app-footer></app-footer>
<!--</div>-->
