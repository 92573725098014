<a [routerLink]="href"
   class="link"
   [ngClass]="arrow ? 'link-w-arrow' : ''">
  <ng-content></ng-content>
  <span *ngIf="arrow"
        class="arrow-line"
        [ngClass]="arrowReverse ? 'arrow-line-reverse' : ''"></span>
</a>

<!-- TODO: arrow-line-reverse animation isnt quite right yet
      It doesnt animate left only -->
