<a class="menu-button"
   [ngClass]="menuOpen ? 'menu-button-open' : 'menu-button-closed'"
   (click)="toggleMenu()">
  <span class="menu-text">Menu</span>
  <div class="menu-icon">
    <span></span>
    <span></span>
    <span></span>
  </div>
</a>
