<div class="uk-container"
     @fadeInOutAnimation>

  <div class="uk-grid uk-margin-large-bottom">

    <div class="uk-width-1-1 uk-width-1-2@s uk-width-3-5@m uk-margin-small-bottom">
      <h1 class="uk-heading-medium">Galerij</h1>
      <p>De galerie bevat een selectie van mijn abstracte en figuratieve werk van de afgelopen jaren.
        Ik vul deze selectie regelmatig aan met nieuw werk. Neem een kijkje!</p>
      <app-link [arrow]="true" [href]="'galerij'">Bekijk alle kunstwerken</app-link>
    </div>

    <div class="uk-width-1-1 uk-width-1-2@s uk-width-2-5@m">
      <app-masonry [columns]="3">
        <app-masonry-item *ngFor="let painting of selection"
                          [painting]="painting">
          <img class="painting-image"
               [src]="painting.image_path"
               [title]="painting.title"
               [alt]="painting.title">
        </app-masonry-item>
      </app-masonry>
    </div>
  </div>

  <div class="uk-grid uk-margin-large-bottom">
    <div class="uk-width-1-1 uk-width-1-2@s uk-width-3-5@m uk-margin-small-bottom">
      <h1 class="uk-heading-medium">Over mij</h1>
      <p>Rene (Tilburg, 1959) wist het op jonge leeftijd al zeker, hij zou boer worden. Na een bezoek aan familie met
        een boerderij in Overijssel was hij meteen verkocht en het leven op het platteland tussen de landbouwhuisdieren
        zou zijn toekomst worden.</p>
      <p>Na de middelbare school werd het wel de Agrarische Hogeschool, maar het runnen van een agrarisch bedrijf lag
        niet in het verschiet.</p>
      <app-link [arrow]="true" [href]="'over-mij'">Lees meer</app-link>
    </div>

    <div class="uk-width-1-1 uk-width-1-2@s uk-width-2-5@m">
      <img src="assets/images/rene-1b.jpg" alt="Ren&eacute; Boons" class="border-radius">
    </div>
  </div>

  <div class="uk-grid">
    <div class="uk-width-1-1 uk-width-3-5@m">
      <h1 class="uk-heading-medium">Contact</h1>
      <p>Bent u geïnteresseerd in het kopen van één of meer van mijn werken?
        Of zoekt u om een andere reden contact?
        U kunt contact met mij opnemen via het contactformulier.</p>
      <app-link [arrow]="true" [href]="'contact'">Neem contact op</app-link>
    </div>

    <div class="uk-width-1-1 uk-width-1-2@s uk-width-2-5@m">
      <div></div>
    </div>
  </div>

</div>
