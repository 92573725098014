import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {slideAnimation, slideYAnimation} from '@animations/slide.animation';
import {fadeAnimation, fadeInOutAnimation} from '@animations/fade.animation';
import {group, transition, trigger, useAnimation} from '@angular/animations';

@Component({
  selector: 'app-masonry',
  templateUrl: './masonry.component.html',
  styleUrls: ['./masonry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeSlideAnimation', [
      transition('* => *', [
        group([
          useAnimation(fadeAnimation),
          useAnimation(slideYAnimation)
        ])
      ], {
        params: {
          time: '400ms',
          opacityFrom: 0,
          opacityTo: 1,
          translateYFrom: '16px',
          translateYTo: '0',
        }
      }),
    ]),
  ]
})
export class MasonryComponent implements OnInit, OnChanges {
  @Input() columns: number | undefined;
  classes: string = '';


  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.columns) {
      switch (changes.columns.currentValue) {
        case 1:
          this.classes = 'uk-child-width-1-1';
          break;
        case 2:
          this.classes = 'uk-child-width-1-2';
          break;
        case 3:
          this.classes = 'uk-child-width-1-3';
          break;
        case 4:
          this.classes = 'uk-child-width-1-4';
          break;
        case 5:
          this.classes = 'uk-child-width-1-5';
          break;
        default:
          this.classes = 'uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@l';
      }
    }

  }

}
