<div class="uk-container"
     @fadeInOutAnimation>
  <h1 class="uk-heading uk-text-center uk-margin-bottom">Over mij</h1>

  <div class="about-intro">

    <div class="about-image">
      <app-image></app-image>
      <img src="assets/images/rene-1d.jpg" alt="Ren&eacute; Boons" title="Ren&eacute; Boons">
    </div>

    <div class="about-text">
      <h2 class="uk-heading-small">Het begin</h2>
      <p>Rene (Tilburg, 1959) wist het op jonge leeftijd al zeker, hij zou boer worden.
        Na een bezoek aan familie met een boerderij in Overijssel was hij meteen verkocht en
        het leven op het platteland tussen de landbouwhuisdieren zou zijn toekomst worden.</p>
      <p>Na de middelbare school werd het wel de Agrarische Hogeschool,
        maar het runnen van een agrarisch bedrijf lag niet in het verschiet.</p>
    </div>
  </div>

  <div class="uk-width-1-1">
    <h2 class="uk-heading-small">De kunstkriebels</h2>
    <p class="about-text">Van jongs af aan was René al geïnteresseerd in
      tekenen en
      kunst in het algemeen.
      Het natekenen van leraren op de middelbare school was een prettige manier om de lessen door te komen.
      Hoewel hij met deze voorliefde tientallen jaren niets deed, kwamen in 2003 de kunstkriebels weer flink
      opborrelen. <br><br>
      Na een bezoek aan het atelier van een kunstenares en het volgen van enkele workshops bij haar,
      is René op bescheiden schaal begonnen met het maken van schilderijen in acryl- en olieverf.
      In deze kunstvorm heeft hij zich vervolgens autodidactisch gevormd. Koeien, varkens, geiten en andere
      landbouwdieren waren in het begin de inspiratiebron voor het figuratief schilderen, later is dat uitgebreid
      met
      andere dieren en landschappen. Het dagelijks werk in de agrarische sector brengt hem nog regelmatig in
      contact
      met allerhande boerenbedrijven waardoor landbouwdieren een vaste bron van inspiratie blijven. Naast het
      figuratieve werk, met oog voor detail, heeft de abstracte kunst ook zijn grote interesse.</p>
  </div>
</div>
