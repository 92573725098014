<div class="uk-container"
     @fadeInOutAnimation>
  <h1 class="uk-heading uk-text-center uk-margin-bottom">Contact</h1>

  <div class="uk-grid">
    <div class="uk-width-1-1 uk-width-1-2@m uk-margin-bottom">
      <p>Bent u geïnteresseerd in het kopen van één of meer van mijn werken? Dat kan; een aantal werken staat te koop!
        U kunt mij via het contactformulier een bericht sturen. Vermeld dan bij welke schilderijen uw interesse ligt.
        Dan zal ik via e-mail met u verder communiceren.
      </p>
      <p>Voor vragen, opmerkingen of andere zaken kunt u mij benaderen via hetzelfde contactformulier.</p>

      <div class="contact-links">
        <app-social-icon [href]="'tel:0623943922'">
          <i class="fa-regular fa-mobile fa-lg"></i>
          <span text>06 2394 3922</span>
        </app-social-icon>
        <app-social-icon [href]="'mailto:r.boons@icloud.com'">
          <i class="fa-regular fa-envelope fa-lg"></i>
          <span text>r.boons@icloud.com</span>
        </app-social-icon>
      </div>
    </div>


    <form class="uk-form uk-width-1-1 uk-width-1-2@m"
          [formGroup]="form"
          (ngSubmit)="sendEmail()">

      <!-- Name -->
      <div class="uk-margin-small-bottom">
        <label class="uk-form-label"
               for="rb-name">
          <span [ngClass]="(!nameValid('required') || !nameValid('maxLength'))
                || name?.untouched ? 'uk-text-danger' : 'uk-text-success'">*</span>
          Naam
        </label>
        <input class="uk-input"
               id="rb-name"
               formControlName="name" type="text" [maxlength]="maxCharsName" required
               [ngClass]="!nameValid('required') ? 'uk-form-danger' : ''">

        <div *ngIf="!nameValid('required')" class="uk-text-meta uk-text-danger uk-margin-small-bottom">
          <i class="fal fa-info-circle"></i>
          Ik heet Ren&eacute;. Wat is uw naam?
        </div>

        <div *ngIf="!nameValid('maxLength')" class="uk-text-meta uk-text-danger uk-margin-small-bottom">
          <i class="fal fa-info-circle"></i>
          Dat is een lange naam, zeg. Heeft u een kortere roepnaam?
        </div>
      </div>

      <!-- Email -->
      <div class="uk-margin-small-bottom">
        <label class="uk-form-label"
               for="rb-email">
          <span [ngClass]="(!emailValid('required') || !emailValid('pattern') || !emailValid('maxLength'))
                || email?.untouched ? 'uk-text-danger' : 'uk-text-success'">*</span>
          E-mailadres
        </label>
        <input class="uk-input"
               id="rb-email"
               type="email" formControlName="email" [maxlength]="maxCharsEmail" required
               [ngClass]="!emailValid('required') || !emailValid('pattern') || !emailValid('maxLength')
                  ? 'uk-form-danger' : ''">

        <div *ngIf="!emailValid('required')" class="uk-text-meta uk-text-danger uk-margin-small-bottom">
          <i class="fal fa-info-circle"></i>
          Uw e-mailadres is vereist om deze e-mail te versturen.
        </div>

        <div *ngIf="!emailValid('pattern')" class="uk-text-meta uk-text-danger uk-margin-small-bottom">
          <i class="fal fa-info-circle"></i>
          Dit e-mailadres lijkt niet te kloppen. Kunt u het controleren?
        </div>

        <div *ngIf="!emailValid('maxLength')" class="uk-text-meta uk-text-danger uk-margin-small-bottom">
          <i class="fal fa-info-circle"></i>
          Heeft uw e-mailadres echt zo veel tekens?
        </div>
      </div>

      <!-- Message -->
      <div class="uk-margin-small-bottom">
        <label class="uk-form-label"
               for="rb-message">
          <span [ngClass]="(!messageValid('required') || !messageValid('maxLength'))
                || message?.untouched ? 'uk-text-danger' : 'uk-text-success'">*</span>
          Vraag, opmerking of anders
        </label>
        <textarea class="uk-textarea"
                  id="rb-message"
                  type="text" formControlName="message" [maxlength]="maxCharsMessage" rows="6" required
                  [ngClass]="!messageValid('required') || !messageValid('maxLength')
                      ? 'uk-form-danger' : ''"></textarea>

        <div *ngIf="!messageValid('required')" class="uk-text-meta uk-text-danger uk-margin-small-bottom">
          <i class="fal fa-info-circle"></i>
          Wat wilt u vragen en/of opmerken?
        </div>

        <div *ngIf="!messageValid('maxLength')" class="uk-text-meta uk-text-danger uk-margin-small-bottom">
          <i class="fal fa-info-circle"></i>
          Uw bericht heeft het limiet van {{ maxCharsMessage }} tekens bereikt.
        </div>
      </div>

      <button class="uk-button uk-button-primary"
              [disabled]="!form.valid">
        <i class="fa-regular fa-paper-plane"></i>
        <span>Verstuur bericht</span>
      </button>


      <p *ngIf="mailSent">Bedankt voor uw bericht! Ik zal binnen redelijke tijd op u reageren.</p>
      <p *ngIf="errorOccurred" class="uk-text-danger">Er is iets misgegaan bij het versturen van uw bericht.</p>
    </form>

  </div>
</div>
