import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {GalleryComponent} from './gallery/gallery.component';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import {GalleryDetailComponent} from './gallery/gallery-detail/gallery-detail.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'galerij', component: GalleryComponent, children: [
      { path: ':title', component: GalleryDetailComponent}
    ]},
  { path: 'over-mij', component: AboutComponent},
  { path: 'contact', component: ContactComponent},
  { path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
