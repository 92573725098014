import {Component, Input, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'app-social-icon',
  templateUrl: './social-icon.component.html',
  styleUrls: ['./social-icon.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class SocialIconComponent {
  @Input() href: string | undefined;
  @Input() size: string = '30px'; // in pixels
}
