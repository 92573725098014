import {Component, EventEmitter, Input, Output} from '@angular/core';
import {slideAnimation} from '@animations/slide.animation';
import {fadeInOutAnimation} from '@animations/fade.animation';
import {Painting} from '../../../gallery/painting';
import {UrlParamUtil} from '@utils/url-param.util';
import {Router} from '@angular/router';

@Component({
  selector: 'app-masonry-item',
  templateUrl: './masonry-item.component.html',
  styleUrls: ['./masonry-item.component.scss'],
  animations: [slideAnimation, fadeInOutAnimation]
})
export class MasonryItemComponent {
  @Input() painting: Painting | undefined;
  @Output() itemSelected = new EventEmitter<Painting>();

  constructor(private router: Router) {
  }

  selectPainting(painting: Painting | undefined): void {
    if (!painting) {
      throw Error('Could not select painting; no painting argument was given');
    }
    const param = UrlParamUtil.ConvertToUrlParameter(painting.title);
    this.router.navigate(['galerij', param])
      .then(() => this.itemSelected.emit(painting));
  }

}
