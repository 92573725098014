import { Pipe, PipeTransform } from '@angular/core';
import {Painting} from '../gallery/painting';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(painting: Painting): string {
    if (!painting.for_sale) {
      if (painting.sold) return 'Verkocht';
      else return 'N.T.K.';
    }

    return `${painting.price} EU`;
  }
}
