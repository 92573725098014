import { Component } from '@angular/core';
import {toggleMenuAnimation} from '@animations/toggle-menu.animation';
import {fadeInOutAnimation} from '@animations/fade.animation';
import {slideAnimation} from '@animations/slide.animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideAnimation]
})
export class AppComponent {
  title = 'René Boons';
  menuOpen = false;
  windowScrollLocked = false;

  onActivate() {
    this.menuOpen = false;
    this.setWindowScrollLock(false);

    // Temp values for testing
    // this.menuOpen = true;
    // this.windowScrollLocked = true;
  }

  setMenuOpen(menuOpen: boolean) {
    this.menuOpen = menuOpen;
    this.setWindowScrollLock(menuOpen)
  }

  setWindowScrollLock(lock: boolean) {
    this.windowScrollLocked = lock;
    this.toggleWindowScrollLock();
  }

  toggleWindowScrollLock() {
    if (this.windowScrollLocked) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }
  }

  private disableScroll() {
    // document.body.classList.add('no-scroll');
    // document.body.addEventListener('touchmove', e => e.preventDefault());

    const x = window.pageYOffset || document.documentElement.scrollTop;
    const y= window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = () => window.scrollTo(x, y);
  }

  private enableScroll() {
    // document.body.classList.remove('no-scroll');
    // document.body.removeEventListener('touchmove', e => e.preventDefault());

      window.onscroll = function() {};
  }
}
