import {NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {environment} from '@env';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NavComponent } from './nav/nav.component';
import {LazyLoadImgDirective} from '@directives/lazy-load-img.directive';
import {NgOptimizedImage} from '@angular/common';
import { HomeComponent } from './home/home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MenuButtonComponent } from './nav/menu-button/menu-button.component';
import { MenuComponent } from './nav/menu/menu.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SocialIconComponent } from '@components/social-icon/social-icon.component';
import { LogoComponent } from '@components/logo/logo.component';
import { GalleryDetailComponent } from './gallery/gallery-detail/gallery-detail.component';
import {UrlParamPipe} from '@pipes/url-param.pipe';
import { LinkComponent } from '@components/link/link.component';
import {getFunctions, provideFunctions} from '@angular/fire/functions';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MasonryComponent } from './_components/masonry/masonry.component';
import { ImageComponent } from './_components/image/image.component';
import { MasonryItemComponent } from '@components/masonry/masonry-item/masonry-item.component';
import { PricePipe } from './_pipes/price.pipe';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    GalleryComponent,
    NavComponent,
    LazyLoadImgDirective,
    HomeComponent,
    MenuButtonComponent,
    MenuComponent,
    SocialIconComponent,
    LogoComponent,
    GalleryDetailComponent,
    UrlParamPipe,
    LinkComponent,
    MasonryComponent,
    ImageComponent,
    MasonryItemComponent,
    PricePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions(getApp(), 'europe-west1')),
    NgOptimizedImage,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
