import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent implements OnInit {
  @Input() menuOpen: boolean | undefined;
  @Output() menuToggled = new EventEmitter<boolean>();

  constructor() {
  }
  ngOnInit(): void {
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
    this.menuToggled.emit(this.menuOpen);
  }

}
