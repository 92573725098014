<a [href]="href" target="_blank"
   class="social-icon">
  <div class="icon-container">
    <div class="icon-content">
      <ng-content></ng-content>
    </div>
    <div class="icon-background"></div>
  </div>
  <div class="icon-text">
    <ng-content select="[text]"></ng-content>
  </div>
</a>
