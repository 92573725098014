import {animate, animation, keyframes, style, transition, trigger, useAnimation} from '@angular/animations';

const duration = '400ms';
const distance = '16px'

export const slideYAnimation = animation([
  style({
    transform: 'translateY({{ translateYFrom }})',
  }), // Initial style
  animate('{{ time }}',
    keyframes([
      style({transform: 'translateY({{ translateYTo }})'}),
    ])
  )
]);
export const slideAnimation =
  trigger('slideUpAnimation', [
    transition('void => *', [
      useAnimation(slideYAnimation, {
        params: {
          time: duration,
          translateYFrom: distance,
          translateYTo: '0',
        }
      }),
    ]),
    transition('* => void', [
      useAnimation(slideYAnimation, {
        params: {
          time: duration,
          translateYFrom: '0',
          translateYTo: distance,
        }
      }),
    ])
  ]);
