<div class="uk-container"
     @fadeInOutAnimation>
  <h1 class="uk-heading uk-text-center uk-margin-bottom">Galerij</h1>
</div>

<router-outlet></router-outlet>

<div class="uk-container">
  <div class="gallery-buttons">
    <button class="uk-hidden@s"
            [ngClass]="masonryColumnCount == 1 ? 'active' : ''"
            (click)="handleColumnCountButton(1)"><i class="fa-regular fa-square fa-lg"></i></button>
    <button class=""
            [ngClass]="masonryColumnCount == 2 ? 'active' : ''"
            (click)="handleColumnCountButton(2)"><i class="fa-regular fa-grid-2 fa-lg"></i></button>
    <button class=""
            [ngClass]="masonryColumnCount == 3 ? 'active' : ''"
            (click)="handleColumnCountButton(3)"><i class="fa-regular fa-grid-3 fa-lg"></i></button>
    <button class="uk-visible@s"
            [ngClass]="masonryColumnCount == 4 ? 'active' : ''"
            (click)="handleColumnCountButton(4)"><i class="fa-regular fa-grid-4 fa-lg"></i></button>
    <button class="uk-visible@m"
            [ngClass]="masonryColumnCount == 5 ? 'active' : ''"
            (click)="handleColumnCountButton(5)"><i class="fa-regular fa-grid-5 fa-lg"></i></button>

    <button class=""
            [ngClass]="masonryColumnCount == null ? 'active' : ''"
            (click)="handleColumnCountButton(undefined)"><span>AUTO</span></button>
  </div>

  <app-masonry [columns]="masonryColumnCount">
    <app-masonry-item *ngFor="let painting of paintings; let i = index"
                      [painting]="painting"
                      (itemSelected)="handlePaintingSelected()">
      <img class="painting-image"
           [src]="painting.image_path"
           [title]="painting.title"
           [alt]="painting.title">
    </app-masonry-item>
  </app-masonry>

</div>

