import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() menuOpen: boolean | undefined;
  @Output() menuToggled = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  setMenuOpen(menuOpen: boolean) {
    this.menuToggled.emit(menuOpen);
  }
}
