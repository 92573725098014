import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Email} from './email';
import {slideAnimation} from '@animations/slide.animation';
import {fadeInOutAnimation} from '@animations/fade.animation';
import {Functions, httpsCallable} from '@angular/fire/functions';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [fadeInOutAnimation, slideAnimation]
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  loading = false;
  mailSent = false;
  errorOccurred = false;
  readonly maxCharsName = 64;
  readonly maxCharsEmail = 128;
  readonly maxCharsMessage = 512;

  constructor(private functions: Functions) {
    this.form = this.generateForm();
  }

  ngOnInit(): void {
  }

  get name() {
    return this.form.get('name');
  }

  get email() {
    return this.form.get('email');
  }

  get message() {
    return this.form.get('message');
  }

  nameValid = (error: string): boolean => this.controlValid(this.name!, error);
  emailValid = (error: string): boolean => this.controlValid(this.email!, error);
  messageValid = (error: string): boolean => this.controlValid(this.message!, error);

  private controlValid(control: AbstractControl, error: string): boolean {
    return !control?.errors?.[error]!! || control?.untouched;
  }

  sendEmail(): void {
    this.errorOccurred = false;
    this.loading = true;

    const input = this.form.value;
    const email: Email = {
      sender: {
        name: input.name,
        email: input.email
      },
      message: input.message,
    };


    const sendContactFormEmailToRene = httpsCallable(this.functions, 'sendContactFormEmailToRene');
    sendContactFormEmailToRene(email)
      .then((data: any) => {
        this.form.reset();
        this.mailSent = true;
      })
      .then((data: any) => {
        const sendContactFormEmailToSender = httpsCallable(this.functions, 'sendContactFormEmailToSender');
        sendContactFormEmailToSender(email)
          .then((data: any) => {
            // ...
          });
      })
      .catch(err => {
        console.log('error sendContactFormEmailToRene', err);
      })
      .finally(() => this.loading = false);
  }

  private generateForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.maxCharsName)
      ]),
      email: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.maxCharsEmail),
        Validators.pattern(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)
      ]),
      message: new FormControl(null, [
        Validators.required,
        Validators.maxLength(this.maxCharsMessage)
      ])
    });
  }

}
