import {Component, OnDestroy, OnInit} from '@angular/core';
import {Painting} from './painting';
import {PaintingService} from './painting.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UrlParamUtil} from '@utils/url-param.util';
import {fadeInOutAnimation} from '@animations/fade.animation';

declare const imagesLoaded: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  animations: [fadeInOutAnimation]
})
export class GalleryComponent implements OnInit, OnDestroy {
  paintings: Painting[];
  painting: Painting | undefined;
  imagesLoadedCount = 0;
  masonryColumnCount: number | undefined;
  private paintingSubscription: Subscription;
  private lastWindowWidth: number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private paintingService: PaintingService) {
    this.paintings = this.paintingService.getAll();

    this.paintingSubscription = this.paintingService.getPainting()
      .subscribe(painting => {
        this.painting = painting;
        this.waitForImages();
      });

    this.lastWindowWidth = window.innerWidth;
    window.addEventListener('resize', (ev) => {
      const windowWidth = window.innerWidth;
      if (this.lastWindowWidth != windowWidth) {
        this.masonryColumnCount = undefined;
        this.lastWindowWidth = windowWidth;
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.paintingSubscription.unsubscribe();
  }

  handleColumnCountButton = (amount: number | undefined): void => {
    if (amount) {
      this.masonryColumnCount = amount;
    } else {
      this.masonryColumnCount = undefined;
    }
  };

  setGalleryData(item: Element, index: number): void {
    const painting = this.paintings[index];
    const title = painting.title;
    const size = painting.size;
    const technique = painting.technique;
    // item.setAttribute('data-title', `${title} (${size.width} x ${size.height})`);
    item.setAttribute('data-title', `${title}`);
  }

  /**
   * Resize the items when all the images inside the masonry grid
   * finish loading. This will ensure that all the content inside our
   * masonry items is visible.
   *
   * @uses ImagesLoaded
   * @uses resizeMasonryItem
   */
  waitForImages(): void {
    const allItems = document.getElementsByClassName('gallery-item');
    for (let i = 0; i < allItems.length; i++) {
      new imagesLoaded(allItems[i], (instance: any) => {
        this.imagesLoadedCount++;
        if (this.imagesLoadedCount == allItems.length) {
          this.paintingService.setImagesLoaded();
        } // Should be handled by a different service
        const item = instance.elements[0];
        this.handleImageLoaded(item, i);
      });
    }
  }

  handleImageLoaded(item: Element, index: number) {
    // Set item data (title, size, technique)
    this.setGalleryData(item, index);

    // Set img class for animation.
    // const random = Math.random() * 200 + 400;
    // const imgEl = item.children[0] as HTMLElement;
    // if (this.paintingService.getImagesLoaded()) { // Should be handled by a different service
    //   imgEl.style.opacity = '1';
    // } else {
    //   setTimeout(() => imgEl.classList.add('loaded'), random);
    // }
  }

  handlePaintingSelected() {
    this.scrollToDetailEl();
  }

  private scrollToDetailEl() {
    const detailEl = this.getDetailEl();
    if (!detailEl) {
      throw Error('element \'.app-gallery-detail\' was not found');
    }
    detailEl!.scrollIntoView();
  }

  private getDetailEl(tries: number = 5): HTMLElement | undefined {
    if (tries == 0) {
      return undefined;
    }
    tries--;

    const detailEl = document.querySelector('app-gallery-detail');
    if (detailEl) return detailEl as HTMLElement;

    setTimeout(() => console.log(detailEl, 'tries left', tries), 80);
    return this.getDetailEl(tries);
  }
}
