import {Component, OnDestroy, OnInit} from '@angular/core';
import {fadeInOutAnimation} from '@animations/fade.animation';
import {slideAnimation} from '@animations/slide.animation';
import {Painting} from '../gallery/painting';
import {Subscription} from 'rxjs';
import {PaintingService} from '../gallery/painting.service';
import {UrlParamUtil} from '@utils/url-param.util';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeInOutAnimation, slideAnimation]
})
export class HomeComponent implements OnInit, OnDestroy {
  private paintings: Painting[];
  selection: Painting[];
  private readonly selectedTitles = [
    'Green Forest',
    'Schelpenzoekers op het wad',
    'The Suggestion Of Depth',
    'Oehoe II',
    'Reiger',
    'Tok!',
  ]; // A selection of paintings to show in a minified masonry.

  constructor(private paintingService: PaintingService) {
    this.paintings = this.paintingService.getAll();
    this.selection = this.paintings
      .filter(p => this.selectedTitles.findIndex(title => title == p.title) != -1)
      .sort((a, b) => this.selectedTitles.indexOf(a.title) - this.selectedTitles.indexOf(b.title));

    const [desiredLength, actualLength] = [this.selectedTitles.length, this.selection.length];
    if (desiredLength != actualLength) {
      throw Error(`Tried to select ${desiredLength} paintings; only selected ${actualLength}`);
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
