<div class="image-wrapper">
  <img *ngIf="image && loaded; else placeholder"
       class="painting-image"
       [ngSrc]="image.source_path"
       [title]="image.title"
       [alt]="image.title"
       [width]="image.width"
       [height]="image.height">

  <ng-template #placeholder>
    <div class="image-placeholder"></div>
  </ng-template>
</div>

