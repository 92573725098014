import {Pipe, PipeTransform} from '@angular/core';
import {UrlParamUtil} from '@utils/url-param.util';

@Pipe({
  name: 'urlParam'
})
export class UrlParamPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    return UrlParamUtil.ConvertToUrlParameter(value);
  }
}
