import {animate, animation, keyframes, style, transition, trigger, useAnimation} from '@angular/animations';

const duration = '400ms';

export const fadeAnimation = animation([
  style({opacity: '{{ opacityFrom }}'}), // Initial style
  animate('{{ time }}',
    keyframes([
      style({opacity: '{{ opacityTo }}'}),
    ])
  )
]);
export const fadeInOutAnimation =
  trigger('fadeInOutAnimation', [
    transition('void => *', [
      useAnimation(fadeAnimation, {
        params: {
          time: duration,
          opacityFrom: 0,
          opacityTo: 1,
        }
      }),
    ]),
    transition('* => void', [
      useAnimation(fadeAnimation, {
        params: {
          time: duration,
          opacityFrom: 1,
          opacityTo: 0,
        }
      }),
    ])
  ]);
