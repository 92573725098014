<div class="uk-container">
  <nav>

    <a class="nav-item" routerLink="">
      <div id="artist-name">Ren&eacute; Boons</div>
      <div id="artist-role">Kunstenaar</div>
    </a>

    <ul class="nav-item nav-item nav-item-center uk-visible@m">
      <li><a routerLink="" routerLinkActive="nav-item-active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
      <li><a routerLink="galerij" routerLinkActive="nav-item-active">Galerij</a></li>
      <li><a routerLink="over-mij" routerLinkActive="nav-item-active">Over mij</a></li>
      <li><a routerLink="contact" routerLinkActive="nav-item-active">Contact</a></li>
      <!--          <li>-->
      <!--            <a href="#">Parent</a>-->
      <!--            <div class="uk-navbar-dropdown">-->
      <!--              <ul class="uk-nav uk-navbar-dropdown-nav">-->
      <!--                <li class="uk-active"><a href="#">Active</a></li>-->
      <!--                <li><a href="#">Item</a></li>-->
      <!--                <li><a href="#">Item</a></li>-->
      <!--              </ul>-->
      <!--            </div>-->
      <!--          </li>-->
    </ul>

    <ul class="nav-item">
<!--      <li class="uk-visible@s">-->
<!--        <app-social-icon [href]="'https://www.instagram.com/rebo013/'">-->
<!--          <i class="fa-brands fa-instagram fa-lg"></i></app-social-icon>-->
<!--      </li>-->
<!--      <li class="uk-visible@s">-->
<!--        <app-social-icon [href]="'https://www.facebook.com/rebo013/'">-->
<!--          <i class="fa-brands fa-facebook-f fa-lg"></i></app-social-icon>-->
<!--      </li>-->

      <!--      <li><button class="uk-button uk-button-primary uk-button-small">-->
      <!--        <i class="fa-regular fa-pen-nib"></i></button></li>-->
      <!--      <li><a routerLink="">Uitloggen</a></li>-->
      <li class="uk-hidden@m">
        <app-menu-button [menuOpen]="menuOpen"
                         (menuToggled)="setMenuOpen($event)"></app-menu-button>
      </li>
    </ul>

  </nav>
</div>

<!--<p class="true-center">&#8593;<br>true center</p>-->

